<template>
  <div class="contents register wedding">
    <div class="title flexB">
      <h2>상담내역 관리</h2>
    </div>

    <div class="box one">
      <h3 class="mb20">
        <span
          >{{ consultType == "tour" || "TOUR" ? "투어" : "일반" }} 상담</span
        >
      </h3>
      <div class="inputBox">
        <div class="flexL">
          <p class="bold">이름</p>
          <p>{{ author.username }}</p>
          <router-link
            class="basic"
            target="_blank"
            :to="`/admin/userDetail?id=${author._id}`"
            ><span class="material-icons"> open_in_new </span></router-link
          >
        </div>
        <div class="flexL">
          <p class="bold">결혼 예정일</p>
          <p>{{ moment(weddingDate).format("YYYY년 MM월 DD일") }}</p>
        </div>
        <div class="flexL">
          <p class="bold">투어 요청일</p>
          <p>
            {{ moment(visitDate).format("YYYY년 MM월 DD일") }} {{ visitTime }}
          </p>
        </div>

        <div class="flexL" v-if="tourType == 'planner'">
          <p class="bold">투어 상담 플래너</p>
          <p>{{ planner ? planner.plannerName : "" }}</p>
        </div>
        <div class="flexL" v-else-if="tourType == 'weddingPlace'">
          <p class="bold">투어 장소</p>
          <p>{{ weddingPlace ? weddingPlace.title : "" }}</p>
        </div>
        <div class="flexL" v-else-if="tourType == 'collection'">
          <p class="bold">컬렉션</p>
          <p>{{ collectionId ? collectionId.title : "" }}</p>
        </div>
        <div class="flexL double">
          <p class="bold">상담 내용</p>
          <!-- <pre>{{ comment }}</pre> -->
          <textarea v-model="comment" cols="30" rows="10"></textarea>
        </div>
      </div>
      <div class="buttonWrap">
        <button class="line" @click="handleDelete">삭제</button>
      </div>
    </div>
    <div class="box one filter">
      <h3 class="mb20">답변하기</h3>
      <div class="inputBox">
        <div class="flexL double">
          <p class="bold">답변 내용</p>
          <textarea v-model="reply" cols="30" rows="10"></textarea>
        </div>
      </div>
      <div class="buttonWrap">
        <router-link to="/admin/consultmanage">목록</router-link>
        <button class="basic" @click="submit">
          {{ isReply ? "답변수정" : "답변작성" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import {
  fetchConsultDetail,
  upadateConsultReply,
  removeConsult,
} from "@/api/admin";
import { format } from "@/mixins/format";
export default {
  mixins: [format],
  data() {
    return {
      moment: moment,
      consultId: "",
      comment: "",
      type: "",
      createdAt: "",
      author: "",
      planner: "",
      isReply: "",
      reply: "",
      consultType: "",
      weddingDate: "",
      visitDate: "",
      visitTime: "",
      tourType: "",
      weddingPlace: "",
      collectionId: "",
    };
  },
  computed: {
    ...mapState(["navbarState"]),
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "7");
  },
  mounted() {
    if (this.$route.query.id) {
      this.consultId = this.$route.query.id;
      this.getConsultDetail();
    }
  },
  methods: {
    getConsultDetail() {
      fetchConsultDetail(this.consultId).then((res) => {
        if (res.data.status == 200) {
          this.consultType = res.data.data.consultType;
          this.tourType = res.data.data.tourType;
          this.comment = res.data.data.comment;
          this.type = res.data.data.type;
          this.createdAt = res.data.data.createdAt;
          this.author = res.data.data.author;
          this.planner = res.data.data.planner;
          this.weddingPlace = res.data.data.weddingPlace;
          this.collectionId = res.data.data.collectionId;
          this.weddingDate = res.data.data.weddingDate;
          this.visitDate = res.data.data.visitDate;
          this.visitTime = res.data.data.visitTime;
          this.isReply = res.data.data.isReply;
          this.reply = res.data.data.reply;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    handleDelete() {
      this.$confirm("삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        removeConsult(this.consultId).then((res) => {
          if (res.data.status == 200) {
            this.$notify({
              title: "Success",
              message: "상담 정보가 삭제 되었습니다.",
              type: "success",
            });
            this.$router.replace("/admin/consultmanage");
          }
        });
      });
    },
    submit() {
      let data = {
        consultId: this.consultId,
        reply: this.reply,
        tourType: this.tourType,
        consultType: this.consultType,
      };

      upadateConsultReply(data).then((res) => {
        if (res.data.status == 200) {
          this.$notify({
            title: "Success",
            message:
              "상담내역 답변이 전달 되었습니다. 투어 상담일 경우 '투어관리'에서 다음단계를 진행해 주세요.",
            type: "success",
          });
          this.$store.dispatch("getNewRequestList");
          this.$router.push("/admin/consultmanage");
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
  },
};
</script>
